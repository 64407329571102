import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { IconContext } from "react-icons";
import { FaSistrix, FaTimes } from "react-icons/fa";
import CardFlip from './CardFlip';

const DraggableCardInArea = ({ index, areaIndex, card, cardWidth, onDelete, onPreview, setIsFlipped, imageDefault }) => {
  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: 'CARD_MOVE',
      item: { id: index, areaId: areaIndex, x: card.x, y: card.y },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index, card],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [ preview ])

  const cardHeight = cardWidth * 1.5; // Задайте высоту карты

  const cardStyle = {
    position: 'absolute',
    width: cardWidth,
    minWidth: "100px",
    minHeight: "150px",
    maxWidth: "200px",
    maxHeight: "300px",
    cursor: 'grab',
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : cardHeight
  }

  return (
    <>
      <div
        ref={dragRef}
        style={{
          ...cardStyle,
          top: card.y,
          left: card.x
        }}
      >
        <CardFlip
          image={card}
          imageDefault={imageDefault}
          index={index}
          cardWidth={cardWidth}
          setIsFlipped={setIsFlipped}
        >
          <IconContext.Provider value={{ size: Math.min(cardHeight * 0.2, 24), className: "controlIcon" }}>
            <div className="areaCardControls">
              <FaTimes onClick={onDelete} />
              <FaSistrix onClick={onPreview} className={card.flipped ? "hiddenControl" : "visibleControl"} />
            </div>
          </IconContext.Provider>
        </CardFlip>
      </div>
    </>
  )
}

export default DraggableCardInArea