import update from 'immutability-helper';
import React, { useEffect, useRef } from "react";
import DraggableCard from './DraggableCard';

const CardGrid = ({ images, imageDefault, setImages, cardWidth }) => {
  const gridStyle = {
    display: "flex",
    overflow: "auto"
  };

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      // Отменить стандартное поведение прокрутки
      event.preventDefault();

      // Прокрутка горизонтально в зависимости от направления колеса мыши
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += event.deltaY;
      }
    };

    // Добавляем обработчик с { passive: false }
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleWheel, { passive: false });
    }

    // Очистка при размонтировании компонента
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const handleDragEnd = (cardIndex) => {
    // Убираем карту из списка (скрываем)
    setImages((prevImages) =>
      update(prevImages, {
        [cardIndex]: {
          $merge: { visible: false }
        }
      })
    )
  }

  const handleFlip = (cardIndex, flipped) => {
    setImages((prevImages) =>
      update(prevImages, {
        [cardIndex]: {
          $merge: { flipped }
        }
      })
    )
  }

  return (
    <div
      style={gridStyle}
      ref={scrollContainerRef}
    >
      {images.map((image, index) => {
        if (!image.visible)
          return <React.Fragment key={index} />

        return <DraggableCard
          key={index}
          index={index}
          image={image}
          imageDefault={imageDefault}
          onDragEnd={() => handleDragEnd(index)} // Обрабатываем начало перетаскивания
          setIsFlipped={(flipped) => handleFlip(index, flipped)}
          cardWidth={cardWidth}
        />
      })}
    </div>
  );
};

export default CardGrid;