import React from 'react';
import { useDragLayer } from 'react-dnd';
import CardFlip from './CardFlip';

const CardDragPreview = ({ cards, imageDefault, cardWidth }) => {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  }

  const getItemStyles = (initialOffset, currentOffset) => {
    if (!initialOffset || !currentOffset)
      return { display: "none" }

    let { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;

    return {
      transform,
      WebkitTransform: transform
    }
  }

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))

  function renderItem() {
    if (itemType === 'CARD_MOVE' || itemType === 'CARD')
      return <CardFlip
        image={cards[item.id]}
        imageDefault={imageDefault}
        index={item.id}
        cardWidth={cardWidth}
        setIsFlipped={() => { }}
      />

    return null
  }

  if (!isDragging)
    return null

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  )
}

export default CardDragPreview