import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import CardFlip from "./CardFlip";

const DraggableCard = ({ index, image, imageDefault, onDragEnd, cardWidth, setIsFlipped }) => {
  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: 'CARD',
      item: { id: index, image: image.url, flipped: image.flipped },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop())
          onDragEnd(); // Уведомляем об удалении карты
      },
    }),
    [image]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [ preview ])

  const cardMargin = Math.min(cardWidth / 10, 20);

  const cardStyle = {
    flex: "0 0 10%",
    padding: "10px",
    boxSizing: "border-box",
    cursor: 'grab',
    margin: cardMargin,
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : ""
  };

  return (
    <div ref={dragRef} style={cardStyle}>
      <CardFlip image={image} imageDefault={imageDefault} index={index} cardWidth={cardWidth} setIsFlipped={setIsFlipped} />
    </div>
  );
}

export default DraggableCard